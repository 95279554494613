import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CDefinition,
  ConceptMedia,
  CCard,
  CMedia,
  LStayContact,
  CSliderCard,
  CRoomMedia,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'CLUB LOUNGE',
            sub: 'クラブラウンジ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/club_lounge/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/club_lounge/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/club_lounge/kv02.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/club_lounge/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/club_lounge/kv03.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/club_lounge/kv03__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/club_lounge/kv04.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/club_lounge/kv04__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: 'クラブラウンジ',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_mb30_pc">
        <LWrap>
          <p className="c_sectLead">
            スカイリゾートフロア「ザ・クラブ」にご宿泊のお客様専用のラウンジです。
            <br />
            滞在中にご利用いただけるご朝食やお飲物の他、観光のご案内などきめ細かいサービスをご提供いたします。
          </p>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '営業時間',
                text: (
                  <>
                    <p>7:00～21:00</p>
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList u_mb90">
            <li>
              65F
              クラブラウンジのご利用は、チェックイン後からチェックアウトまでのご滞在中に限ります。
            </li>
            <li>状況により変更となる可能性がございます。</li>
          </ul>
          <CSliderCard
            exClass="u_mb80"
            col={2}
            data={[
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge01_01.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge01_02.png',
                      alt: '',
                    },
                  },
                ],
                title: <>ご朝食</>,
                content: (
                  <>
                    7:00～10:00
                    <br />
                    シェフが目の前で仕上げるオムレツや神奈川県産野菜を使ったサラダをはじめ、スープ、カレー、毎朝ホテルで焼き上げるパンなどをブッフェスタイルでご用意しております。
                  </>
                ),
              },
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge02_01.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge02_02.png',
                      alt: '',
                    },
                  },
                ],
                title: <>ティータイム</>,
                content: (
                  <>
                    11:00～15:00
                    <br />
                    クッキーなどのお菓子をご用意しております。
                  </>
                ),
              },
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge03_01.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge03_02.png',
                      alt: '',
                    },
                  },
                ],
                title: <>アフタヌーン</>,
                content: (
                  <>
                    15:00～18:00
                    <br />
                    ホテルメイドのサンドウィッチやミニケーキなどをご自由にお召しあがりいただけます。
                    <br />
                    Twiningsの紅茶もご用意しております。
                  </>
                ),
              },
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge04_01.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/club_lounge/img_club_lounge04_02.png',
                      alt: '',
                    },
                  },
                ],
                title: <>イブニング</>,
                content: (
                  <>
                    18:00～21:00
                    <br />
                    キッシュやマリネなどの洋風オードブルやチーズ、ミックスナッツ、小鉢などをご用意しております。
                    <ul className="c_noteList u_mt30">
                      <li>
                        18:00以降は小学生以下のお子様のご入場をご遠慮いただきます。
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
          <p className="c_headingLv4">スカイリゾートフロア「ザ・クラブ」<br className='u_sp' />宿泊者限定サービス</p>
          <CMedia
            exClass="u_mt60_pc u_mb100 u_mb80_sp"
            align="center"
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/club_lounge/img_club_lounge05.png',
                  alt: '',
                },
                title: <>66F　レセプション</>,
                text: (
                  <>
                    10:30～21:00
                    <br />
                    専用デスクにてゆったりとお手続きいただけます。
                    <ul className="c_noteList u_mt30">
                      <li>ラウンジ営業時間外は1Fフロントをご利用ください。</li>
                    </ul>
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/floor/club_lounge/img_club_lounge06.png',
                  alt: '',
                },
                title: <>コンシェルジュサービス</>,
                text: (
                  <>
                    快適なご滞在となるよう専任のスタッフがご案内いたします。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/floor/club_lounge/img_club_lounge07.png',
                  alt: '',
                },
                title: <>ランドマークスパ</>,
                text: (
                  <>
                    ご滞在中何度でも無料でご利用いただけます。
                  </>
                ),
                btn: {
                  label: 'ランドマークスパ',
                  link: {
                    href: '/facilities/landmark_spa/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect02 u_bgWhite">
        <LWrap>
          <p className="c_headingLv2 c_headingLv2__large u_colorGold">クラブラウンジが利用できる客室</p>
          <CRoomMedia
            reverse={true}
            data={[
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club04.png',
                      alt: '',
                    },
                  },
                ],
                title: <>スカイリゾートフロア「ザ・クラブ」</>,
                floor: '65F 〜 66F',
                text: (
                  <>
                    スイートルームを含む、ホテル最高級カテゴリーのクラブフロア。
                    <br />
                    地上255mから眺める横浜の景色とともに、ラグジュアリーで贅沢なひとときをお過ごしいただけます。
                  </>
                ),
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#cornerDouble',
                    title: {
                      en: 'CORNER DOUBLE',
                      ja: 'コーナーダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/club_floor/',
                  text: 'スカイリゾートフロア「ザ・クラブ」',
                  size: 'large'
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite04.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite05.png',
                      alt: '',
                    },
                  },
                ],
                title: <>スイートルーム／和室</>,
                floor: '65F 〜 66F',
                text: (
                  <>
                    スカイリゾートフロア「ザ・クラブ」の中でも、65～290平方メートルのゆとりある客室。贅を尽くしたスイートルームです。
                    <br />
                    プレジデンシャルスイート、 和室スイート、ロイヤルスイートのご予約はお電話で承ります。
                  </>
                ),
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#comfortSuite',
                    title: {
                      en: 'COMFORT SUITE',
                      ja: 'コンフォートスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#executiveSuite',
                    title: {
                      en: 'EXECUTIVE SUITE',
                      ja: 'エグゼクティブスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#presidentalSuite',
                    title: {
                      en: (
                        <>
                          PRESIDENTIAL <br className="u_sp" />
                          SUITE
                        </>
                      ),
                      ja: 'プレジデンシャルスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#japaneseSuite',
                    title: {
                      en: 'JAPANESE SUITE',
                      ja: '和室スイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#royalSuite',
                    title: {
                      en: 'ROYAL SUITE',
                      ja: 'ロイヤルスイート',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/suite_floor/',
                  text: 'スイートルーム',
                  size: 'large'
                },
              },
            ]}
          />
          <ul className="c_noteList u_mbMedium">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
